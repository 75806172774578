export const seo = {
  url: 'wycena',
  title: {
    pl: 'Kontenery Morskie | Wycena Kontenerów',
    en: 'Shipping containers | Containers quote',
    zh: '海运集装箱 | 集装箱报价',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: `✓ Container quote ✓ 20'DV containers ✓ 40'DV containers ✓Container store ✓Fast delivery`,
    zh: `✓ 集装箱报价 ✓ 20'DV 集装箱 ✓ 40'DV 集装箱 ✓ 集装箱商店 ✓ 快速交货`,
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Wycena Kontenerów',
    en: 'Container Quote',
    zh: '集装箱报价',
  },
  desc: {
    pl: 'Złóż zapytanie na wycenę kontenerów. Zyskaj Partnera Biznesowego z dużą dostępnością kontenerów oraz szybką realizację.',
    en: 'Submit a request for a container quote. Get a Business Partner with high container availability and fast turnaround.',
    zh: '提交集装箱报价请求。获取集装箱可用性高、周转速度快的业务合作伙伴。',
  },
}

export const main = {
  title: {
    pl: 'Zyskaj Partnera Biznesowego z dużą dostępnością kontenerów oraz szybką realizacją.',
    en: 'Looking for transport? Make a free quotation request for the transport service.',
    zh: '正在寻找交通工具？免费索取交通服务报价。',
  },
  texts: [
    {
      pl: '<span>Wybierz doświadczoną firmę transportową. Zrealizowaliśmy ponad <strong>2 mln transportów</strong> w Europie i na świecie. Określ miejsce i datę załadunku oraz rozładunku Twojego towaru. Opcjonalnie podaj parametry poszczególnych ładunków. W wiadomości możesz opisać założenia i potrzeby zlecenia. Nasi spedytorzy skontaktują się z Tobą mailowo lub telefonicznie <strong>najszybciej, jak to możliwe</strong>.</span>',
      en: '<span>Choose an experienced forwarding company. We have completed over <strong>2 million shipments</strong> in Europe and around the world. Specify the place and date of loading and unloading your goods. Optionally, enter the parameters of individual loads. In the message, you can describe the assumptions and needs of the order. Our forwarders will contact you by e-mail or phone <strong> as soon as possible </strong>.</span>',
      zh: '<span>Choose an experienced forwarding company. We have completed over <strong>2 million shipments</strong> in Europe and around the world. Specify the place and date of loading and unloading your goods. Optionally, enter the parameters of individual loads. In the message, you can describe the assumptions and needs of the order. Our forwarders will contact you by e-mail or phone <strong> as soon as possible </strong>.</span>',
    },
  ],
}
